<template>
  <a-select
    v-if="isAdmin"
    v-model="selected"
    class="w170 ml-40"
    size="small"
    :placeholder="$t('components.description.adminProject')"
    :get-popup-container="(triggerNode) => triggerNode.parentNode"
    @change="onChange">
    <a-icon slot="suffixIcon" type="caret-down"/>
      <a-select-option v-for="proj in projects" :key="proj.id" :value="proj.id">
        {{ proj.name }}
      </a-select-option>
  </a-select>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import { configForPossibleBackendRequest } from '../util/request';
import { setUserProject, setUserSurvey } from '../util/util';

export default {
  name: 'AdminProjectSelector',
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: this.project.id
    };
  },
  computed: {
    ...mapState({
      projects: state => state
        .request.data?.projects?.filter(project => !project._data.archived) || []
    }),
    isAdmin() {
      return this.currentUser.verbs.includes('project.delete');
    }
  },
  methods: {
    async onChange(id) {
      if (this.isAdmin) {
        setUserProject(this.currentUser.id, id);
        const surveysBaseConfig = { method: 'GET', url: `/projects/${id}/surveys` };
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;
        const surveysAxiosConfig = configForPossibleBackendRequest(surveysBaseConfig, token);
        try {
          const surveysResponse = await Vue.prototype.$http.request(surveysAxiosConfig);
          if (surveysResponse.status === 200 && surveysResponse.data) {
            const surveys = surveysResponse.data;
            const payload = (surveys && Array.isArray(surveys) && orderBy(surveys, 'createdAt', 'desc')[0]) || undefined;
            if (payload) {
              setUserSurvey(this.currentUser.id, payload.id);
            }
          }
        } catch (error) {
          // console.error(error);
        } finally {
          window.location.reload();
        }
      }
    }
  }
};
</script>

<style lang="scss">
</style>
